import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    users: 50000,
    revenue: 2.32,    // $2.32M monthly
    costs: 0.3,
    infraCost: 0.05
  },
  {
    users: 100000,
    revenue: 4.64,    // $4.64M monthly (from pricing doc)
    costs: 0.4,
    infraCost: 0.06
  },
  {
    users: 200000,
    revenue: 9.28,    // Linear scaling for demo
    costs: 0.6,
    infraCost: 0.08
  },
  {
    users: 300000,
    revenue: 13.92,
    costs: 0.8,
    infraCost: 0.1
  },
  {
    users: 400000,
    revenue: 18.56,
    costs: 1.2,
    infraCost: 0.15
  },
  {
    users: 500000,
    revenue: 23.20,
    costs: 2.0,
    infraCost: 0.2
  },
  {
    users: 600000,
    revenue: 27.85,   // From new calculation
    costs: 3.0,
    infraCost: 0.3
  }
];

const CostRevenueChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart 
        data={data}
        margin={{ top: 35, right: 30, left: 20, bottom: 80 }}
      >
        <text
          x="50%"
          y={10}
          fill="#666"
          textAnchor="middle"
          dominantBaseline="middle"
          className="text-base font-semibold"
        >
          Monthly Revenue and Cost Projections
        </text>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="users" 
          tickFormatter={(value) => `${value/1000}K`}
          label={{ 
            value: 'Users', 
            position: 'bottom', 
            offset: -20
          }}
          height={75}
        />
        <YAxis 
          tickFormatter={(value) => `$${value}M`}
          label={{ value: 'USD (Millions)', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip 
          formatter={(value, name) => [`$${value}M`, name]}
          labelFormatter={(value) => `${value/1000}k Users`}
        />
        <Legend 
          verticalAlign="bottom"
          align="center"
          offset={50}
          height={36}
        />
        <Line 
          type="monotone" 
          dataKey="revenue" 
          name="Revenue" 
          stroke="#4ade80" 
          strokeWidth={2} 
        />
        <Line 
          type="monotone" 
          dataKey="costs" 
          name="Total Costs" 
          stroke="#f87171" 
          strokeWidth={2} 
        />
        <Line 
          type="monotone" 
          dataKey="infraCost" 
          name="Infrastructure Costs" 
          stroke="#fbbf24" 
          strokeWidth={2} 
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CostRevenueChart;