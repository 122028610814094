import React from 'react';
import PitchDeck from './components/pitch-deck/PitchDeck';

function App() {
  return (
    <div>
      <PitchDeck />
    </div>
  );
}

export default App; 