import React from 'react';
import { Key, Shield, Zap, Coins, Bot, Lock } from 'lucide-react';

const keyFeatures = [
  {
    title: "Unified Token System",
    description: "Single token access across multiple APIs, eliminating fragmented key management",
    impact: "Reduces security vulnerabilities and simplifies integration",
    icon: Key,
    color: "text-blue-400"
  },
  {
    title: "Native AI Integration",
    description: "Built-in support for AI agents to autonomously discover and manage API access",
    impact: "Enables next-generation automation and seamless AI-API interactions",
    icon: Bot,
    color: "text-purple-400"
  },
  {
    title: "Micropayment System",
    description: "Granular, usage-based pricing with innovative token-based billing",
    impact: "Makes API access cost-effective for businesses of all sizes",
    icon: Coins,
    color: "text-green-400"
  },
  {
    title: "Enterprise Security",
    description: "Built to be GDPR and CCPA compliant with comprehensive security measures",
    impact: "Ensures data protection and regulatory compliance",
    icon: Shield,
    color: "text-red-400"
  }
];

const benefits = [
  {
    metric: "90%",
    label: "Reduction in API Integration Time",
    description: "Streamlined token-based access"
  },
  {
    metric: "<1% of API Transaction Cost",
    label: "Management Overhead Cost",
    description: "Providing Enterprise-grade Security and Management"
  },
  {
    metric: "1-Click",
    label: "API Discovery & Access",
    description: "Unified developer and third-party AI agent integration"
  }
];

const SolutionSlide = () => {
  return (
    <div className="space-y-6">
      {/* Solution Overview */}
      <div className="bg-gray-800/50 p-6 rounded-lg">
        <h3 className="text-xl font-semibold text-blue-400 mb-2">One Token Solution</h3>
        <p className="text-gray-300 text-sm sm:text-base mb-6">
          Just as digital keycards revolutionized building access management, OTA revolutionizes API management. We replace fragmented API keys with a unified token system - think of it as a smart keycard that leverages:
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {keyFeatures.map((feature, index) => (
            <div key={index} className="p-4 sm:p-6 bg-gray-700/50 rounded-lg">
              <div className="flex items-center space-x-4">
                <div className={`p-3 rounded-lg bg-gray-800 ${feature.color}`}>
                  <feature.icon size={24} />
                </div>
                <div>
                  <h4 className="font-semibold text-white mb-2">{feature.title}</h4>
                  <p className="text-gray-300 text-sm">{feature.description}</p>
                  <p className="text-blue-400 text-sm mt-2">{feature.impact}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-gray-800/50 p-6 rounded-lg">
        <h4 className="text-xl font-semibold text-blue-400 mb-4">Key Benefits</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          {benefits.map((benefit, index) => (
            <div key={index} className="text-center p-6 bg-gray-700/50 rounded-lg">
              <div className="text-3xl font-bold text-white mb-2">
                {benefit.metric}
              </div>
              <div className="text-blue-300 font-medium mb-1">{benefit.label}</div>
              <p className="text-sm text-gray-300">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Technical Highlights */}
      <div className="bg-blue-500/10 p-6 rounded-lg border border-blue-400/30">
        <h4 className="text-xl font-semibold text-blue-400 mb-4">Technical Architecture</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 text-center">
          <div className="p-6 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-3">
              <Lock className="text-blue-400" size={24} />
            </div>
            <h4 className="font-semibold text-white mb-2">Central Token Broker</h4>
            <p className="text-sm text-gray-300">Secure token management and introspection</p>
          </div>
          <div className="p-6 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-3">
              <Shield className="text-blue-400" size={24} />
            </div>
            <h4 className="font-semibold text-white mb-2">OAuth 2.0 Extensions</h4>
            <p className="text-sm text-gray-300">Enhanced authorization capabilities</p>
          </div>
          <div className="p-6 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-3">
              <Zap className="text-blue-400" size={24} />
            </div>
            <h4 className="font-semibold text-white mb-2">Real-time Analytics</h4>
            <p className="text-sm text-gray-300">Usage tracking and billing integration</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionSlide; 