import React, { useState } from 'react';
import MarketGrowthChart from '../../charts/MarketGrowthChart';
import AIGrowthChart from '../../charts/AIGrowthChart';
import MarketFunnel from '../../charts/MarketFunnel';
import { ResponsiveContainer } from 'recharts';

const growthDrivers = [
  {
    title: "AI Integration",
    description: "Explosive growth in AI agents requiring automated API access",
    metric: "73% of enterprises planning AI integration by 2025",
    icon: "🤖"
  },
  {
    title: "Security & Compliance",
    description: "Growing need for unified security across multiple APIs",
    metric: "87% increase in API security spending",
    icon: "🔒"
  },
  {
    title: "API Economy Evolution",
    description: "Shift towards micropayments and usage-based pricing",
    metric: "Average enterprise uses 900+ API endpoints",
    icon: "💸"
  },
  {
    title: "Market Inefficiency",
    description: "Current solutions not meeting multi-API management needs",
    metric: "$3.6B lost annually to API integration challenges",
    icon: "⚡"
  }
];

const MarketSlide = () => {
  const [expandedDriver, setExpandedDriver] = useState(null);

  return (
    <div className="space-y-8">
      {/* Market Size Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Total Addressable Market</h3>
          <div className="h-[300px] bg-gray-900/50 rounded-lg p-4">
            <MarketGrowthChart />
          </div>
        </div>

        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">AI API Growth</h3>
          <div className="h-[300px] bg-gray-900/50 rounded-lg p-4">
            <AIGrowthChart />
          </div>
        </div>
      </div>

      {/* Market Funnel */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Market Opportunity</h3>
        <div className="h-[400px] bg-gray-900/50 rounded-lg p-4 mb-4"> {/* Reduced from 500px to 400px */}
          <ResponsiveContainer width="100%" height="100%">
            <MarketFunnel />
          </ResponsiveContainer>
        </div>
      </div>

      {/* Growth Drivers Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        {growthDrivers.map((driver, index) => (
          <div
            key={index}
            className={`bg-gray-800/50 p-4 sm:p-6 rounded-lg cursor-pointer transition-all duration-300 ${
              expandedDriver === index ? 'sm:col-span-2' : ''
            }`}
            onClick={() => setExpandedDriver(expandedDriver === index ? null : index)}
          >
            <div className="flex items-center space-x-3">
              <div className="text-2xl">{driver.icon}</div>
              <div>
                <h4 className="font-semibold text-white">{driver.title}</h4>
                <p className="text-gray-300">{driver.description}</p>
                {expandedDriver === index && (
                  <p className="text-blue-400 mt-2">{driver.metric}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketSlide;