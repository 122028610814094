import React from 'react';
import logo from '../../../assets/ota_logo-black.png';

const HeroSlide = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-4 sm:p-6">
      <div className="mb-4 sm:mb-8">
        <div className="w-16 h-16 sm:w-24 sm:h-24 bg-blue-500 rounded-full mx-auto mb-4 sm:mb-6 flex items-center justify-center">
          <img 
            src={logo} 
            alt="OTA Logo" 
            className="w-16 h-16 sm:w-24 sm:h-24 object-contain"
          />
        </div>
      </div>
      
      <h1 className="text-3xl sm:text-5xl font-bold mb-2 sm:mb-4 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
        One Token API
      </h1>
      
      <p className="text-xl sm:text-2xl text-gray-400 mb-4 sm:mb-8">
        One Token. Many Doors.
      </p>
      
      <div className="text-base sm:text-lg text-gray-300 max-w-2xl">
        <p className="mb-4 sm:mb-6">
          Simplifying API Access Management with Unified Token-based Authentication
        </p>
        <div className="flex flex-col sm:flex-row justify-center sm:space-x-8 space-y-4 sm:space-y-0 text-xs sm:text-sm text-gray-400">
          <div>
            <p>Presented by</p>
            <p className="font-semibold">Christian Zimmerman</p>
            <p>CEO & Founder</p>
          </div>
          <div>
            <p>Date</p>
            <p className="font-semibold">{new Date().toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlide; 