import React, { useState } from 'react';
import { Users, Globe, Database, Server } from 'lucide-react';
import CostRevenueChart from '../charts/CostRevenueChart';
import TokenScalingChart from '../charts/TokenScalingChart';

const revenueMetrics = [
  {
    metric: "$0.005",
    label: "Per Token Validation",
    subtext: "Base rate with volume discounts"
  },
  {
    metric: "85%+",
    label: "Gross Margin",
    subtext: "Industry-leading efficiency"
  },
  {
    metric: "$5,000 (regional)/ $24,000 (global)",
    label: "Monthly Infrastructure",
    subtext: "Even at 600K users"
  }
];

const costBreakdown = [
  {
    title: "Infrastructure",
    percentage: "1-2%",
    description: "Highly efficient cloud-native architecture",
    icon: "🚀"
  },
  {
    title: "Development",
    percentage: "40%",
    description: "Platform enhancement and maintenance",
    icon: "💻"
  },
  {
    title: "Sales & Marketing",
    percentage: "35%",
    description: "Market expansion and customer acquisition",
    icon: "📈"
  },
  {
    title: "Operations",
    percentage: "23%",
    description: "Support and business operations",
    icon: "⚙️"
  }
];

const ScalingPhase = ({ icon: Icon, title, items }) => (
  <div className="bg-gray-700/50 rounded-lg p-4 flex flex-col space-y-3">
    <div className="flex items-center space-x-2 text-blue-400">
      <Icon size={20} />
      <h3 className="font-semibold">{title}</h3>
    </div>
    <ul className="text-sm space-y-2 text-gray-300">
      {items.map((item, i) => (
        <li key={i} className="flex items-start space-x-2">
          <div className="min-w-3 h-3 mt-1.5">
            <div className="w-1.5 h-1.5 bg-blue-400 rounded-full"></div>
          </div>
          <span>{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

const BusinessModelSlide = () => {
  const [expandedCost, setExpandedCost] = useState(null);

  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Top Section - Modified grid layout */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
        <div className="space-y-4">
          <h3 className="text-2xl font-semibold text-blue-400">Business Model</h3>
          
          {/* Revenue Metrics */}
          <div className="bg-gray-800/50 rounded-lg p-4">
            <h3 className="text-lg font-semibold text-blue-400 mb-3">Revenue Metrics</h3>
            <div className="space-y-4">
              {revenueMetrics.map((item, index) => (
                <div key={index} 
                     className="flex items-start space-x-3 p-2 hover:bg-gray-700 rounded-lg transition-colors">
                  <div>
                    <div className="text-2xl font-bold text-white">{item.metric}</div>
                    <div className="text-blue-300 font-medium">{item.label}</div>
                    <div className="text-sm text-gray-400">{item.subtext}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full min-h-[300px]">
          <CostRevenueChart />
        </div>
      </div>

      {/* Cost Structure Grid */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-2 sm:mb-4">Cost Structure</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {costBreakdown.map((cost, index) => (
            <div
              key={index}
              className={`p-3 sm:p-4 bg-gray-700 rounded-lg cursor-pointer transition-all duration-300 ${
                expandedCost === index ? 'col-span-1 sm:col-span-2' : ''
              }`}
              onClick={() => setExpandedCost(expandedCost === index ? null : index)}
            >
              <div className="flex items-center space-x-3">
                <span className="text-xl sm:text-2xl">{cost.icon}</span>
                <div>
                  <h4 className="font-semibold text-white text-sm sm:text-base">{cost.title}</h4>
                  <p className="text-blue-400 font-semibold text-sm sm:text-base">{cost.percentage}</p>
                  {expandedCost === index && (
                    <p className="text-xs sm:text-sm text-gray-300 mt-2">{cost.description}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modified Token Scaling Section */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Operational Scaling</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <ScalingPhase
            icon={Users}
            title="Team Scaling"
            items={[
              "Q1: Core team of 4-5 technical experts",
              "Q2: Expand to 6-7 with product focus",
              "Q3: Growth to 8-9 adding sales/support",
              "Q4: Full team of 10-12 across functions"
            ]}
          />
          
          <ScalingPhase
            icon={Database}
            title="Infrastructure Scaling"
            items={[
              "Initial: Regional deployment with 3-node cluster",
              "Growth: Multi-region expansion (5 regions)",
              "Scale: Global presence with 15+ nodes",
              "Optimization: AI-driven resource management"
            ]}
          />
          
          <ScalingPhase
            icon={Server}
            title="Enterprise Capacity"
            items={[
              "1,000 introspections/second per instance",
              "Support for 1.8M active tokens (30-min expiry)",
              "Support 300K concurrent tokens",
              "Redundant multi-region deployment"
            ]}
          />
          
          <ScalingPhase
            icon={Globe}
            title="Market Expansion"
            items={[
              "US launch with regulated industries focus",
              "Expansion to additional regions",
              "Strategic market prioritization",
              "Global coverage across 5 regions"
            ]}
          />
        </div>

        <div className="mt-4">
          <TokenScalingChart />
        </div>
      </div>

      {/* Bottom Highlight */}
      <div className="bg-blue-500/10 p-6 rounded-lg border border-blue-400/30">
        <h3 className="text-xl font-semibold text-blue-400 mb-4">Competitive Advantage</h3>
        <div className="grid grid-cols-3 gap-4 text-center">
          <div className="p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white mb-2">10x Cost-Effective</h4>
            <p className="text-sm text-gray-300">Compared to traditional solutions</p>
          </div>
          <div className="p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white mb-2">Infinite Scalability</h4>
            <p className="text-sm text-gray-300">Cloud-native architecture</p>
          </div>
          <div className="p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white mb-2">High Margins</h4>
            <p className="text-sm text-gray-300">90%+ gross margin</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessModelSlide;