import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const data = [
  { year: '2024', value: 4.37 },
  { year: '2025', value: 5.88 },
  { year: '2026', value: 7.91 },
  { year: '2027', value: 10.64 },
  { year: '2028', value: 14.31 },
  { year: '2029', value: 19.25 },
  { year: '2030', value: 41.50 }
];

const MarketGrowthChart = () => {
  const fontSize = window.innerWidth < 640 ? 10 : 12;

  return (
    <div className="h-full w-full">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 10, left: 25, bottom: 5 }}
        >
          <defs>
            <linearGradient id="marketGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#60A5FA" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#60A5FA" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke="rgba(255, 255, 255, 0.1)"
            vertical={false}
          />
          <XAxis 
            dataKey="year" 
            stroke="#D1D5DB"
            tick={{ 
              fill: '#D1D5DB',
              fontSize: fontSize
            }}
            axisLine={{ stroke: '#4B5563' }}
            orientation="bottom"
          />
          <YAxis
            stroke="#D1D5DB"
            tick={{ 
              fill: '#D1D5DB',
              fontSize: fontSize
            }}
            axisLine={{ stroke: '#4B5563' }}
            tickFormatter={value => `$${value}B`}
            dx={-5}
            domain={[0, 45]}
            ticks={[0, 10, 20, 30, 40]}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: '#1F2937',
              border: '1px solid #374151',
              borderRadius: '0.375rem',
              color: '#D1D5DB',
              fontSize: fontSize
            }}
            formatter={value => [`$${value.toFixed(2)}B`, 'Market Size']}
            labelStyle={{ color: '#D1D5DB', fontSize: fontSize }}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#60A5FA"
            fillOpacity={1}
            fill="url(#marketGradient)"
            name="Market Size"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketGrowthChart;