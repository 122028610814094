import React from 'react';

const SlideLayout = ({ title, subtitle, children, ...props }) => {
  return (
    <div className="p-8" {...props}>
      <div className="mb-8">
        <h2 
          className="text-3xl font-bold text-white mb-2"
          data-slide-title={true}
        >
          {title}
        </h2>
        {subtitle && (
          <p 
            className="text-xl text-gray-300"
            data-slide-subtitle={true}
          >
            {subtitle}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default SlideLayout; 