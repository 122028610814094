import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Controls = ({ currentSlide, totalSlides, onPrevious, onNext }) => {
  return (
    <div className="flex justify-between items-center mt-8">
      <button 
        onClick={onPrevious}
        disabled={currentSlide === 0}
        className="flex items-center space-x-2 px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 transition-all"
      >
        <ChevronLeft className="w-5 h-5" />
        <span>Previous</span>
      </button>

      <div className="flex space-x-2">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <button
            key={index}
            onClick={() => onNext(index)}
            className={`w-3 h-3 rounded-full transition-all ${
              currentSlide === index ? 'bg-blue-500' : 'bg-gray-600'
            }`}
          />
        ))}
      </div>

      <button 
        onClick={() => onNext(currentSlide + 1)}
        disabled={currentSlide === totalSlides - 1}
        className="flex items-center space-x-2 px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 transition-all"
      >
        <span>Next</span>
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );
};

export default Controls; 