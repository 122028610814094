import React, { useState } from 'react';
import { Users, Megaphone, Server, Building, ChevronRight, Milestone } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const fundingScenariosData = {
  conservative: {
    amount: '2.5M',
    allocation: [
      {
        name: 'Team Building',
        value: 60,
        icon: Users,
        color: '#60A5FA',
        details: [
          'Technical co-founder',
          'Senior developers',
          'Security experts',
          'Basic product team'
        ]
      },
      {
        name: 'Marketing',
        value: 25,
        icon: Megaphone,
        color: '#34D399',
        details: [
          'Developer community',
          'Enterprise partnerships',
          'Regional brand development',
          'Content strategy'
        ]
      },
      {
        name: 'Infrastructure',
        value: 10,
        icon: Server,
        color: '#F87171',
        details: [
          'Regional cloud infrastructure',
          'Initial database scaling',
          'Core security implementations',
          'Essential development tools'
        ]
      },
      {
        name: 'Operations',
        value: 5,
        icon: Building,
        color: '#A78BFA',
        details: [
          'Office space',
          'Basic legal & compliance',
          'Administrative costs',
          'Essential software licenses'
        ]
      }
    ],
    milestones: [
      {
        quarter: 'Q1',
        milestones: [
          'Core team hiring completed',
          'Regional infrastructure setup',
          'Initial marketing campaign'
        ]
      },
      {
        quarter: 'Q2',
        milestones: [
          'Beta launch',
          '3-5 enterprise clients',
          'Community building'
        ]
      },
      {
        quarter: 'Q3',
        milestones: [
          'Regional product launch',
          'Strategic partnerships',
          'Focus on regulated industries'
        ]
      },
      {
        quarter: 'Q4',
        milestones: [
          'Expand to 12-15 enterprise clients',
          'Core features rollout',
          'Seed+ preparation'
        ]
      }
    ]
  },
  aggressive: {
    amount: '5M',
    allocation: [
      {
        name: 'Team Building',
        value: 55,
        icon: Users,
        color: '#60A5FA',
        details: [
          'Technical co-founder',
          'Expanded development team',
          'Security team',
          'Full product & design team',
          'Sales team'
        ]
      },
      {
        name: 'Marketing',
        value: 30,
        icon: Megaphone,
        color: '#34D399',
        details: [
          'Global developer community',
          'Major enterprise partnerships',
          'International brand development',
          'Comprehensive marketing campaigns',
          'Industry events'
        ]
      },
      {
        name: 'Infrastructure',
        value: 10,
        icon: Server,
        color: '#F87171',
        details: [
          'Global cloud infrastructure',
          'Advanced database scaling',
          'Enhanced security systems',
          'Comprehensive development tools',
          'AI integration systems'
        ]
      },
      {
        name: 'Operations',
        value: 5,
        icon: Building,
        color: '#A78BFA',
        details: [
          'Multiple office locations',
          'International legal & compliance',
          'Enhanced administrative systems',
          'Enterprise software suite'
        ]
      }
    ],
    milestones: [
      {
        quarter: 'Q1',
        milestones: [
          'Full team hiring initiated',
          'Global infrastructure setup',
          'International marketing launch'
        ]
      },
      {
        quarter: 'Q2',
        milestones: [
          'Accelerated beta launch',
          '8-10 enterprise clients',
          'US & EU market entry'
        ]
      },
      {
        quarter: 'Q3',
        milestones: [
          'Global product launch',
          'Major strategic partnerships',
          'Multi-sector expansion'
        ]
      },
      {
        quarter: 'Q4',
        milestones: [
          'Expand to 25-30 enterprise clients',
          'Advanced features rollout',
          'Series A preparation'
        ]
      }
    ]
  }
};

const UseOfFundsSlide = () => {
  const [selectedScenario, setSelectedScenario] = useState('conservative');
  const [selectedAllocation, setSelectedAllocation] = useState(null);

  const currentScenario = fundingScenariosData[selectedScenario];

  return (
    <div className="space-y-4 sm:space-y-8 p-2 sm:p-6">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <h3 className="text-xl sm:text-2xl font-semibold text-blue-400">Investment Request</h3>
        
        {/* Scenario Selection */}
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
          <button
            className={`px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg transition-colors ${
              selectedScenario === 'conservative' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
            }`}
            onClick={() => setSelectedScenario('conservative')}
          >
            Conservative ($2.5M)
          </button>
          <button
            className={`px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg transition-colors ${
              selectedScenario === 'aggressive' ? 'bg-green-500 text-white' : 'bg-gray-700 text-gray-300'
            }`}
            onClick={() => setSelectedScenario('aggressive')}
          >
            Aggressive ($5M)
          </button>
        </div>

        <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-6 rounded-lg border border-blue-400/30">
          <div className="text-center">
            <div className="text-4xl font-bold text-white mb-2">${currentScenario.amount}</div>
            <div className="text-blue-400">Seed Funding Goal</div>
          </div>
        </div>
      </div>

      {/* Fund Allocation Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
        {/* Pie Chart */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-4">Fund Allocation</h4>
          <div className="w-full aspect-square max-w-[300px] mx-auto">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={currentScenario.allocation}
                  cx="50%"
                  cy="50%"
                  innerRadius="45%"
                  outerRadius="70%"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {currentScenario.allocation.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: 'none',
                    borderRadius: '0.375rem',
                    padding: '0.5rem',
                  }}
                  formatter={(value) => `${value}%`}
                />
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    paddingTop: '20px',
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Allocation Details */}
        <div className="col-span-1 lg:col-span-2 bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-4">Detailed Breakdown</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {currentScenario.allocation.map((allocation, index) => (
              <div
                key={index}
                className={`p-3 sm:p-4 bg-gray-700/50 rounded-lg cursor-pointer transition-all duration-300 hover:bg-gray-700 ${
                  selectedAllocation === index ? 'ring-2 ring-blue-400' : ''
                }`}
                onClick={() => setSelectedAllocation(selectedAllocation === index ? null : index)}
              >
                <div className="flex items-center space-x-3">
                  <div className="p-2 rounded-lg" style={{ backgroundColor: allocation.color + '20' }}>
                    <allocation.icon style={{ color: allocation.color }} size={24} />
                  </div>
                  <div>
                    <h5 className="font-semibold text-white">{allocation.name}</h5>
                    <p className="text-blue-400 font-semibold">{allocation.value}%</p>
                  </div>
                </div>
                {selectedAllocation === index && (
                  <div className="mt-3 space-y-2">
                    {allocation.details.map((detail, i) => (
                      <div key={i} className="flex items-center text-sm text-gray-300">
                        <ChevronRight className="w-4 h-4 mr-2" />
                        {detail}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Milestones Timeline */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-4">Key Milestones</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {currentScenario.milestones.map((quarter, index) => (
            <div key={index} className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
              <div className="flex items-center space-x-2 mb-3">
                <Milestone className="text-blue-400" size={20} />
                <h5 className="font-semibold text-white">{quarter.quarter}</h5>
              </div>
              <ul className="space-y-2">
                {quarter.milestones.map((milestone, i) => (
                  <li key={i} className="text-sm text-gray-300 flex items-start">
                    <ChevronRight className="w-4 h-4 mr-2 mt-0.5 text-blue-400" />
                    {milestone}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseOfFundsSlide; 