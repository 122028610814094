import React from 'react';
import { AlertTriangle, Lock, Shield, Zap } from 'lucide-react';

const risks = {
  market: {
    title: "Market Risks",
    icon: AlertTriangle,
    color: "text-red-400",
    bgColor: "bg-red-900/20",
    borderColor: "border-red-900/20",
    items: [
      "Enterprise sales cycle length",
      "API provider adoption rate",
      "Competitive response from incumbents"
    ]
  },
  technical: {
    title: "Technical Risks",
    icon: Lock,
    color: "text-orange-400",
    bgColor: "bg-orange-900/20",
    borderColor: "border-orange-900/20",
    items: [
      "Token system scaling challenges",
      "AI integration complexity",
      "Infrastructure performance at scale"
    ]
  },
  mitigations: {
    title: "Mitigations",
    icon: Shield,
    color: "text-green-400",
    bgColor: "bg-green-900/20",
    borderColor: "border-green-900/20",
    items: [
      "Phased regional deployment",
      "Patent-protected technology",
      "Conservative vs aggressive scaling plans"
    ]
  },
  operational: {
    title: "Operational Efficiency",
    icon: Zap,
    color: "text-blue-400",
    bgColor: "bg-blue-900/20",
    borderColor: "border-blue-900/20",
    items: [
      "Only $0.015 per user at 300K users",
      "Scales to $0.04 per user globally",
      "90%+ gross margins maintained"
    ]
  }
};

const infrastructureMetrics = [
  {
    label: "Initial Phase",
    value: "$4.6K",
    subtext: "per month"
  },
  {
    label: "Global Phase",
    value: "$23.5K",
    subtext: "per month"
  },
  {
    label: "Cost Per User",
    value: "$0.04",
    subtext: "at scale",
    highlight: true
  }
];

const RiskSlide = () => {
  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Risk Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
        {Object.entries(risks).map(([key, section]) => (
          <div
            key={key}
            className={`bg-gray-800/50 p-4 sm:p-8 rounded-lg border ${section.borderColor}`}
          >
            <div className="flex items-center gap-3 mb-4">
              <div className={`${section.bgColor} ${section.color} p-2 rounded-lg`}>
                <section.icon size={24} />
              </div>
              <h4 className="text-lg font-semibold text-white">
                {section.title}
              </h4>
            </div>
            <ul className="space-y-2">
              {section.items.map((item, index) => (
                <li key={index} className="text-gray-300 text-sm flex items-center">
                  <span className="mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Infrastructure Metrics */}
      <div className="bg-gray-800/50 p-4 sm:p-8 rounded-lg border border-blue-900/20">
        <h4 className="text-lg font-semibold text-blue-400 mb-3">Infrastructure Cost Efficiency</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
          {infrastructureMetrics.map((metric, index) => (
            <div key={index} className="text-center p-4 bg-gray-900/50 rounded-lg">
              <p className="text-sm text-gray-400">{metric.label}</p>
              <p className={`text-2xl font-bold ${metric.highlight ? 'text-green-400' : 'text-white'}`}>
                {metric.value}
              </p>
              <p className="text-xs text-gray-400">{metric.subtext}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RiskSlide; 