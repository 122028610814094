import React from 'react';
import { Line } from 'react-chartjs-2';

const AIGrowthChart = () => {
  const data = {
    labels: ['2022', '2023', '2024', '2025', '2026'],
    datasets: [{
      label: 'AI-Driven API Calls (Billions)',
      data: [100, 189, 357, 675, 1276],
      borderColor: '#60A5FA',
      tension: 0.4,
      fill: true,
      backgroundColor: 'rgba(96, 165, 250, 0.1)',
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'AI-Driven API Calls (Billions)',
        color: '#D1D5DB',
        font: {
          size: window.innerWidth < 640 ? 10 : 12
        },
        padding: {
          top: window.innerWidth < 640 ? 5 : 10
        }
      }
    },
    scales: {
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#D1D5DB',
          font: {
            size: window.innerWidth < 640 ? 10 : 12
          }
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#D1D5DB',
          font: {
            size: window.innerWidth < 640 ? 10 : 12
          }
        }
      }
    }
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      <Line data={data} options={options} />
    </div>
  );
};

export default AIGrowthChart; 