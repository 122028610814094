import React, { useState, useRef, useCallback, useEffect } from 'react';
import { slides } from '../../constants/slides';
import Controls from './navigation/Controls';
import SlideLayout from './layouts/SlideLayout';
import HeroSlide from './slides/HeroSlide';
import MarketSlide from './slides/MarketSlide';
import BusinessModelSlide from './slides/BusinessModelSlide';
import ProblemSlide from './slides/ProblemSlide';
import SolutionSlide from './slides/SolutionSlide';
import RevenueSlide from './slides/RevenueSlide';
import CompetitiveSlide from './slides/CompetitiveSlide';
import TractionSlide from './slides/TractionSlide';
import GoToMarketSlide from './slides/GoToMarketSlide';
import UseOfFundsSlide from './slides/UseOfFundsSlide';
import TeamSlide from './slides/TeamSlide';
import RiskSlide from './slides/RiskSlide';
import PartnershipAndTimingSlide from './slides/PartnershipAndTimingSlide';

const PitchDeck = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const targetRef = useRef();

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const handleSlideChange = useCallback((newSlide) => {
    scrollToTop();
    setCurrentSlide(newSlide);
  }, [scrollToTop]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
      }

      switch (event.code) {
        case 'Space':
        case 'ArrowRight':
          if (currentSlide < slides.length - 1) {
            handleSlideChange(currentSlide + 1);
          }
          break;
        case 'ArrowLeft':
          if (currentSlide > 0) {
            handleSlideChange(currentSlide - 1);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentSlide, handleSlideChange]);

  const renderSlideContent = () => {
    const slide = slides[currentSlide];
    switch (slide.type) {
      case 'hero':
        return <HeroSlide />;
      case 'problem':
        return <ProblemSlide />;
      case 'solution':
        return <SolutionSlide />;
      case 'chart':
        return slide.chartComponent === 'MarketGrowthChart' ? <MarketSlide /> : <div>Chart slide coming soon...</div>;
      case 'business-model':
        return <BusinessModelSlide />;
      case 'revenue':
        return <RevenueSlide />;
      case 'comparison':
        return <CompetitiveSlide />;
      case 'traction':
        return <TractionSlide />;
      case 'strategy':
        return <GoToMarketSlide />;
      case 'funds':
        return <UseOfFundsSlide />;
      case 'risks':
        return <RiskSlide />;
      case 'team':
        return <TeamSlide />;
      case 'partnership':
        return <PartnershipAndTimingSlide />;
      default:
        return <div>Slide content coming soon...</div>;
    }
  };

  const handleDownloadPDF = () => {
    fetch('/one-token-api-pitch-deck.pdf')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        // Check if the blob size is too small (indicating a potential error)
        if (blob.size < 1000) {  // Less than 1KB
          throw new Error('PDF file appears to be invalid or empty');
        }
        
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'one-token-api-pitch-deck.pdf';
        
        // Force the MIME type
        const blobWithType = new Blob([blob], { type: 'application/pdf' });
        const urlWithType = window.URL.createObjectURL(blobWithType);
        link.href = urlWithType;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        window.URL.revokeObjectURL(urlWithType);
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
        alert('Failed to download PDF. Please try again later.');
      });
  };

  const handleContact = () => {
    window.location.href = 'mailto:christian.zimmerman@onetokenapi.com?subject=One Token API Inquiry';
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-gray-900 bg-opacity-90 backdrop-blur-sm z-50 p-2 sm:p-4">
        <div className="flex flex-col sm:flex-row justify-between items-center max-w-7xl mx-auto space-y-2 sm:space-y-0">
          <div className="flex items-center space-x-4">
            <div className="text-lg sm:text-xl font-bold">OTA Pitch Deck</div>
            <div className="text-xs sm:text-sm text-gray-400">
              Slide {currentSlide + 1} of {slides.length}
            </div>
          </div>
          <div className="flex items-center space-x-2 sm:space-x-4">
            <button 
              onClick={handleDownloadPDF}
              className="text-sm sm:text-base bg-blue-600 hover:bg-blue-700 px-3 py-1 sm:px-4 sm:py-2 rounded transition-colors"
            >
              Download PDF
            </button>
            <button 
              onClick={handleContact}
              className="text-sm sm:text-base bg-green-600 hover:bg-green-700 px-3 py-1 sm:px-4 sm:py-2 rounded transition-colors"
            >
              Contact Us
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-24 sm:pt-20 pb-16 px-2 sm:px-4">
        <div 
          className="max-w-7xl mx-auto" 
          ref={targetRef}
          style={{ backgroundColor: '#1a1a1a' }}
        >
          <SlideLayout 
            title={slides[currentSlide].title}
            subtitle={slides[currentSlide].subtitle}
          >
            {renderSlideContent()}
          </SlideLayout>
        </div>

        <Controls
          currentSlide={currentSlide}
          totalSlides={slides.length}
          onPrevious={() => handleSlideChange(Math.max(0, currentSlide - 1))}
          onNext={(index) => handleSlideChange(
            typeof index === 'number' 
              ? index 
              : Math.min(slides.length - 1, currentSlide + 1)
          )}
        />
      </div>

      {/* Footer */}
      <footer className="fixed bottom-0 w-full bg-gray-900 bg-opacity-90 backdrop-blur-sm p-2 sm:p-4">
        <div className="max-w-7xl mx-auto text-xs sm:text-sm text-gray-400 flex justify-between">
          <div>© 2024 One Token API, Inc.</div>
          <div className="hidden sm:block">Press 'Space' or Arrow Keys to Navigate</div>
        </div>
      </footer>
    </div>
  );
};

export default PitchDeck;
