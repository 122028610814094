import React, { useState } from 'react';
import { Users, Target, Building, Shield, Database, BarChart, Server, Globe } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

// Updated growth data with more realistic 36-month timeline
const baseGrowthData = [
  { quarter: 'Q1 Y1', conservative: 75000, aggressive: 100000, conservativeCumulative: 75000, aggressiveCumulative: 100000 },
  { quarter: 'Q2 Y1', conservative: 150000, aggressive: 250000, conservativeCumulative: 225000, aggressiveCumulative: 350000 },
  { quarter: 'Q3 Y1', conservative: 300000, aggressive: 500000, conservativeCumulative: 525000, aggressiveCumulative: 850000 },
  { quarter: 'Q4 Y1', conservative: 475000, aggressive: 750000, conservativeCumulative: 1000000, aggressiveCumulative: 1600000 },
  { quarter: 'Q1 Y2', conservative: 800000, aggressive: 1200000, conservativeCumulative: 1800000, aggressiveCumulative: 2800000 },
  { quarter: 'Q2 Y2', conservative: 1100000, aggressive: 1700000, conservativeCumulative: 2900000, aggressiveCumulative: 4500000 },
  { quarter: 'Q3 Y2', conservative: 1400000, aggressive: 2200000, conservativeCumulative: 4300000, aggressiveCumulative: 6700000 },
  { quarter: 'Q4 Y2', conservative: 1700000, aggressive: 2900000, conservativeCumulative: 6000000, aggressiveCumulative: 9600000 },
  { quarter: 'Q1 Y3', conservative: 2500000, aggressive: 4000000, conservativeCumulative: 8500000, aggressiveCumulative: 13600000 },
  { quarter: 'Q2 Y3', conservative: 3300000, aggressive: 5200000, conservativeCumulative: 11800000, aggressiveCumulative: 18800000 },
  { quarter: 'Q3 Y3', conservative: 4100000, aggressive: 6500000, conservativeCumulative: 15900000, aggressiveCumulative: 25300000 },
  { quarter: 'Q4 Y3', conservative: 5000000, aggressive: 8000000, conservativeCumulative: 20900000, aggressiveCumulative: 33300000 }
];

const revenueBreakdown = [
  { name: 'Subscriptions', value: 45 },
  { name: 'Introspection Fees', value: 35 },
  { name: 'API Provider Fees', value: 15 },
  { name: 'Add-on Services', value: 5 }
];

const COLORS = ['#60A5FA', '#34D399', '#F87171', '#A78BFA'];

const RevenueSlide = () => {
  const [activeScenario, setActiveScenario] = useState('conservative');

  // Update the data transformation to include cumulative values
  const growthData = baseGrowthData.map(data => ({
    quarter: data.quarter,
    [activeScenario]: data[activeScenario],
    [`${activeScenario}Cumulative`]: data[`${activeScenario}Cumulative`]
  }));

  const formatCurrency = (value) => {
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    return `$${(value / 1000).toFixed(0)}K`;
  };

  const formatUsers = (value) => {
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    return `${(value / 1000).toFixed(0)}K`;
  };

  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <h3 className="text-xl sm:text-2xl font-semibold text-blue-400">Revenue Model & Projections</h3>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            className={`px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg transition-colors ${
              activeScenario === 'conservative' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
            }`}
            onClick={() => setActiveScenario('conservative')}
          >
            Conservative Growth
          </button>
          <button
            className={`px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg transition-colors ${
              activeScenario === 'aggressive' ? 'bg-green-500 text-white' : 'bg-gray-700 text-gray-300'
            }`}
            onClick={() => setActiveScenario('aggressive')}
          >
            Aggressive Growth
          </button>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
        {/* Monthly Revenue Chart */}
        <div className="col-span-1 sm:col-span-2 bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">
            {activeScenario === 'conservative' ? 
              'Conservative Growth Scenario - $2.5M Funding' : 
              'Aggressive Growth Scenario - $5M Funding'}
          </h4>
          <p className="text-sm text-gray-400 mb-4">Three Year Revenue Projection</p>
          <div className="h-64 sm:h-96">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart 
                data={growthData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis 
                  dataKey="quarter" 
                  stroke="#9CA3AF"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis 
                  stroke="#9CA3AF"
                  tickFormatter={(value) => `$${(value/1000000).toFixed(1)}M`}
                  domain={[0, activeScenario === 'conservative' ? 22000000 : 35000000]}
                />
                <Tooltip 
                  contentStyle={{ backgroundColor: '#1F2937', border: 'none' }}
                  labelStyle={{ color: '#9CA3AF' }}
                  formatter={(value, name) => {
                    const formattedValue = `$${(value/1000000).toFixed(2)}M`;
                    const label = name.includes('Cumulative') ? 'Cumulative Revenue' : 'Quarterly Revenue';
                    return [formattedValue, label];
                  }}
                  labelFormatter={(label) => `${label}`}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey={activeScenario} 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  name="Quarterly Revenue"
                />
                <Line 
                  type="monotone" 
                  dataKey={`${activeScenario}Cumulative`}
                  stroke="#059669" 
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  name="Cumulative Revenue"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Revenue Mix */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Revenue Mix</h4>
          <div className="h-48 sm:h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={revenueBreakdown}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="45%"
                  innerRadius="45%"
                  outerRadius="70%"
                  paddingAngle={5}
                >
                  {revenueBreakdown.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#1F2937', 
                    border: 'none',
                  }}
                  itemStyle={{ color: 'white' }}
                  formatter={(value, name) => [`${value}%`, name]}
                  labelStyle={{ color: 'white' }}
                />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{
                    paddingTop: '20px'
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Access Model Comparison */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-4">Like modern building access systems, we offer:</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <h5 className="font-semibold text-white mb-2">Pay-per-use</h5>
            <p className="text-gray-300 text-sm">Only pay when you enter</p>
          </div>
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <h5 className="font-semibold text-white mb-2">Bulk access</h5>
            <p className="text-gray-300 text-sm">Unlimited entries to designated areas</p>
          </div>
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <h5 className="font-semibold text-white mb-2">Enterprise-wide access</h5>
            <p className="text-gray-300 text-sm">Full building portfolio management</p>
          </div>
        </div>
      </div>

      {/* Pricing Tiers */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        {/* Developer Pricing */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg h-full">
          <h5 className="text-lg font-medium text-blue-300 mb-4">Developer Plans</h5>
          <div className="grid grid-cols-2 gap-4 h-[calc(100%-2rem)]">
            {[
              {
                name: "Free Trial",
                price: "Free",
                icon: Users,
                color: "text-blue-400",
                features: ["3 APIs", "1K introspections", "$0.005/introspection"]
              },
              {
                name: "Developer",
                price: "$9/mo",
                icon: Target,
                color: "text-green-400",
                features: ["15 APIs", "10K introspections", "$0.005/introspection"]
              },
              {
                name: "Business",
                price: "$99/mo",
                icon: Building,
                color: "text-purple-400",
                features: ["50 APIs", "100K introspections", "$0.004/introspection"]
              },
              {
                name: "Enterprise",
                price: "$999/mo",
                icon: Shield,
                color: "text-red-400",
                features: ["Unlimited APIs", "1M introspections", "$0.003/introspection"]
              }
            ].map((tier, index) => (
              <div key={index} className="bg-gray-800/50 p-4 rounded-lg flex flex-col">
                <div className="flex items-center space-x-2 mb-3">
                  <div className="p-2 rounded-lg bg-gray-700/50">
                    <tier.icon className={tier.color} size={20} />
                  </div>
                  <h5 className="font-semibold text-white">{tier.name}</h5>
                </div>
                <div className="text-xl sm:text-2xl font-bold text-gray-300 mb-3">{tier.price}</div>
                <ul className="text-sm text-gray-400 space-y-2">
                  {tier.features.map((feature, i) => (
                    <li key={i} className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* API Provider Pricing */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg h-full">
          <h5 className="text-lg font-medium text-blue-300 mb-4">API Provider Plans</h5>
          <div className="grid grid-cols-2 gap-4 h-[calc(100%-2rem)]">
            {[
              {
                name: "Starter",
                price: "$29/mo",
                icon: Database,
                color: "text-blue-400",
                features: [
                  "10K introspections/mo",
                  "Basic analytics",
                  "Standard support",
                  "Basic billing mgmt"
                ]
              },
              {
                name: "Growth",
                price: "$99/mo",
                icon: BarChart,
                color: "text-green-400",
                features: [
                  "100K introspections/mo",
                  "Enhanced analytics",
                  "Priority support",
                  "Security monitoring"
                ]
              },
              {
                name: "Professional",
                price: "$299/mo",
                icon: Server,
                color: "text-purple-400",
                features: [
                  "500K introspections/mo",
                  "Advanced analytics",
                  "Premium support",
                  "Custom integrations"
                ]
              },
              {
                name: "Enterprise",
                price: "From $999/mo",
                icon: Globe,
                color: "text-red-400",
                features: [
                  "500K+ introspections/mo",
                  "Custom analytics",
                  "Dedicated support",
                  "Enterprise security"
                ]
              }
            ].map((tier, index) => (
              <div key={index} className="bg-gray-800/50 p-4 rounded-lg flex flex-col">
                <div className="flex items-center space-x-2 mb-3">
                  <div className="p-2 rounded-lg bg-gray-700/50">
                    <tier.icon className={tier.color} size={20} />
                  </div>
                  <h5 className="font-semibold text-white">{tier.name}</h5>
                </div>
                <div className="text-xl sm:text-2xl font-bold text-gray-300 mb-3">{tier.price}</div>
                <ul className="text-sm text-gray-400 space-y-2">
                  {tier.features.map((feature, i) => (
                    <li key={i} className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="bg-blue-500/10 p-4 sm:p-6 rounded-lg border border-blue-400/30">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <div className="text-center p-2 sm:p-0">
            <div className="text-xl sm:text-2xl font-bold text-white mb-2">
              {activeScenario === 'conservative' ? '38.4M' : '76.8M'}
            </div>
            <div className="text-sm sm:text-base text-blue-400">Annual Revenue Target Y2</div>
          </div>
          <div className="text-center p-2 sm:p-0">
            <div className="text-xl sm:text-2xl font-bold text-white mb-2">85%+</div>
            <div className="text-sm sm:text-base text-blue-400">Gross Margin</div>
          </div>
          <div className="text-center p-2 sm:p-0">
            <div className="text-xl sm:text-2xl font-bold text-white mb-2">
              {activeScenario === 'conservative' ? '300K' : '600K'}
            </div>
            <div className="text-sm sm:text-base text-blue-400">Active Users Y2</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueSlide; 