import React, { useState } from 'react';
import { Users,Target, BrainCircuit, Shield, Code, LineChart, Building, ChevronRight } from 'lucide-react';
import headshot from '../../../assets/christian-zimmerman.jpg';

const leadershipProfile = {
  name: "Christian Zimmerman",
  title: "Founder & CEO",
  experience: "25+ years Enterprise IT Leadership",
  expertise: [
    "Token Management System Architecture (Patent-Pending)",
    "Enterprise Infrastructure at Scale (19,000+ users)",
    "Federal and Healthcare Compliance (HIPAA, FERPA)",
    "Enterprise AI Infrastructure Optimization",
    "Federal Security Clearance (Public Trust Level 6C)"
  ],
  achievements: [
    "Filed Three Provisional Patents in API Management and AI Systems",
    "Developed OTA Proof of Concept Using AI-Assisted Development",
    "Architected US Dept of Education Infrastructure Supporting $1T in Federal Student Loans",
    "Led Multi-Site Healthcare Systems Supporting 19,000+ Users",
    "Optimized Enterprise AI Infrastructure, Significantly Reducing Cloud Costs"
  ],
  businessInsights: [
    "Enterprise System Implementation in Regulated Industries",
    "Cross-Functional Team Leadership and Collaboration",
    "Risk Management in Federal and Healthcare Environments",
    "Large-Scale System Architecture and Integration",
    "Proven History of Infrastructure Innovation and Optimization"
  ]
};

const keyHires = [
  {
    role: "Technical Co-Founder / CTO",
    timing: "Immediate",
    icon: BrainCircuit,
    color: "text-purple-400",
    bgColor: "bg-purple-400/20",
    responsibilities: [
      "Technical vision & architecture",
      "Engineering team leadership",
      "AI integration strategy",
      "Infrastructure scaling"
    ]
  },
  {
    role: "Senior Security Engineer",
    timing: "Q1",
    icon: Shield,
    color: "text-red-400",
    bgColor: "bg-red-400/20",
    responsibilities: [
      "Security architecture",
      "Compliance frameworks",
      "Token system security",
      "Threat modeling"
    ]
  },
  {
    role: "Senior Backend Developer",
    timing: "Q1",
    icon: Code,
    color: "text-blue-400",
    bgColor: "bg-blue-400/20",
    responsibilities: [
      "Core platform development",
      "API implementation",
      "System optimization",
      "Technical documentation"
    ]
  },
  {
    role: "Product Manager",
    timing: "Q2",
    icon: Target,
    color: "text-green-400",
    bgColor: "bg-green-400/20",
    responsibilities: [
      "Product roadmap",
      "Feature prioritization",
      "Market requirements",
      "User experience"
    ]
  }
];

const TeamSlide = () => {
  const [selectedHire, setSelectedHire] = useState(null);
  const [expandedSection, setExpandedSection] = useState('expertise');

  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Leadership Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
        {/* Profile Section with Experience Toggle */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg space-y-6">
          <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6">
            <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-gray-700 overflow-hidden">
              <img src={headshot} alt="CEO" className="w-full h-full object-cover" />
            </div>
            <div className="text-center sm:text-left">
              <h3 className="text-xl sm:text-2xl font-semibold text-white">{leadershipProfile.name}</h3>
              <p className="text-blue-400 text-sm sm:text-base mb-2">{leadershipProfile.title}</p>
              <p className="text-gray-300 text-sm sm:text-base">{leadershipProfile.experience}</p>
            </div>
          </div>

          {/* Experience Toggle */}
          <div>
            <div className="grid grid-cols-3 gap-2 sm:gap-4 mb-4">
              {['expertise', 'achievements', 'business'].map((section) => (
                <button
                  key={section}
                  onClick={() => setExpandedSection(section)}
                  className={`p-2 sm:p-3 rounded-lg text-xs sm:text-sm font-medium transition-colors ${
                    expandedSection === section
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              ))}
            </div>

            <div className="space-y-3">
              {expandedSection === 'expertise' && leadershipProfile.expertise.map((item, index) => (
                <div key={index} className="flex items-center space-x-3 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
                  <Building className="text-blue-400 w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="text-gray-300 text-sm sm:text-base">{item}</span>
                </div>
              ))}
              {expandedSection === 'achievements' && leadershipProfile.achievements.map((item, index) => (
                <div key={index} className="flex items-center space-x-3 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
                  <Target className="text-green-400 w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="text-gray-300 text-sm sm:text-base">{item}</span>
                </div>
              ))}
              {expandedSection === 'business' && leadershipProfile.businessInsights.map((item, index) => (
                <div key={index} className="flex items-center space-x-3 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
                  <LineChart className="text-red-400 w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="text-gray-300 text-sm sm:text-base">{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Growth Strategy Section */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg h-full">
          <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-6">Team Growth Trajectory</h3>
          <div className="space-y-6">
            <div className="p-6 bg-gray-700/50 rounded-lg">
              <div className="flex items-center space-x-3 mb-6">
                <div className="p-2.5 rounded-lg bg-blue-400/20">
                  <Users className="text-blue-400 w-6 h-6" />
                </div>
                <div>
                  <h4 className="text-white font-medium text-lg">Conservative Plan</h4>
                  <p className="text-blue-400 text-sm">$2.5M Funding Scenario</p>
                </div>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {[
                  { quarter: 'Q1', range: '4-5', description: 'Core Team Formation' },
                  { quarter: 'Q2', range: '6-7', description: 'Initial Expansion' },
                  { quarter: 'Q3', range: '8-9', description: 'Product Development' },
                  { quarter: 'Q4', range: '10-12', description: 'Market Growth' }
                ].map((item, index) => (
                  <div key={index} className="bg-gray-800/80 p-4 rounded-lg text-center">
                    <div className="text-blue-400 font-medium text-lg mb-2">{item.quarter}</div>
                    <div className="text-white text-2xl font-bold mb-2">{item.range}</div>
                    <div className="text-gray-400 text-xs">{item.description}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-6 bg-gray-700/50 rounded-lg">
              <div className="flex items-center space-x-3 mb-6">
                <div className="p-2.5 rounded-lg bg-green-400/20">
                  <Users className="text-green-400 w-6 h-6" />
                </div>
                <div>
                  <h4 className="text-white font-medium text-lg">Aggressive Plan</h4>
                  <p className="text-green-400 text-sm">$5M Funding Scenario</p>
                </div>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {[
                  { quarter: 'Q1', range: '6-8', description: 'Rapid Team Assembly' },
                  { quarter: 'Q2', range: '10-12', description: 'Accelerated Growth' },
                  { quarter: 'Q3', range: '15-18', description: 'Market Expansion' },
                  { quarter: 'Q4', range: '20-25', description: 'Full Scale Operations' }
                ].map((item, index) => (
                  <div key={index} className="bg-gray-800/80 p-4 rounded-lg text-center">
                    <div className="text-green-400 font-medium text-lg mb-2">{item.quarter}</div>
                    <div className="text-white text-2xl font-bold mb-2">{item.range}</div>
                    <div className="text-gray-400 text-xs">{item.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Hires Section */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h3 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Key Hires (Next 12 Months)</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {keyHires.map((hire, index) => (
            <div
              key={index}
              className="p-4 bg-gray-700/50 rounded-lg cursor-pointer"
              onClick={() => setSelectedHire(selectedHire === index ? null : index)}
            >
              <div className="flex items-center space-x-3">
                <div className={`p-2 rounded-lg ${hire.bgColor}`}>
                  <hire.icon className={hire.color} size={window.innerWidth < 640 ? 16 : 20} />
                </div>
                <div>
                  <h4 className="font-semibold text-white text-sm sm:text-base">{hire.role}</h4>
                  <p className={`${hire.color} text-xs sm:text-sm`}>{hire.timing}</p>
                </div>
              </div>
              {selectedHire === index && (
                <div className="mt-3 space-y-2">
                  {hire.responsibilities.map((resp, i) => (
                    <div key={i} className="flex items-center text-xs sm:text-sm text-gray-300">
                      <ChevronRight className="w-3 h-3 sm:w-4 sm:h-4 mr-2" />
                      {resp}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSlide; 