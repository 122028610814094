import React from 'react';
import { Check, Users, Building } from 'lucide-react';

const currentMilestones = [
  {
    title: "MVP Development",
    description: "Core functionality complete",
    icon: Check,
    color: "text-green-400"
  },
  {
    title: "Beta Testing",
    description: "Early adopter feedback",
    icon: Users,
    color: "text-blue-400"
  },
  {
    title: "Enterprise Interest",
    description: "Initial discussions",
    icon: Building,
    color: "text-purple-400"
  }
];

const TractionSlide = () => {
  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <p className="text-gray-300 text-sm sm:text-lg">
          Achieved significant progress with minimal investment through efficient AI-assisted development
        </p>
      </div>

      {/* Current Achievements Grid */}
      <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Current Achievements</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-6">
          {currentMilestones.map((milestone, index) => (
            <div key={index} className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
              <div className="flex items-start space-x-3 sm:space-x-4">
                <div className={`p-2 sm:p-3 rounded-lg bg-gray-700 ${milestone.color}`}>
                  <milestone.icon size={window.innerWidth < 640 ? 20 : 24} />
                </div>
                <div>
                  <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">{milestone.title}</h4>
                  <p className="text-xs sm:text-sm text-gray-300">{milestone.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Development Progress */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
        <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Technical Progress</h4>
          <div className="space-y-3 sm:space-y-4">
            <div className="flex items-center space-x-3 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
              <div className="p-1.5 sm:p-2 rounded-full bg-green-400/20">
                <Check className="text-green-400 w-4 h-4 sm:w-5 sm:h-5" />
              </div>
              <div>
                <p className="text-white font-medium text-sm sm:text-base">Core Components Built</p>
                <p className="text-xs sm:text-sm text-gray-300">Production-ready foundation</p>
              </div>
            </div>
            <div className="flex items-center space-x-3 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
              <div className="p-1.5 sm:p-2 rounded-full bg-green-400/20">
                <Check className="text-green-400 w-4 h-4 sm:w-5 sm:h-5" />
              </div>
              <div>
                <p className="text-white font-medium text-sm sm:text-base">AI-Assisted Development</p>
                <p className="text-xs sm:text-sm text-gray-300">Efficient resource utilization</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Target Markets</h4>
          <div className="space-y-3 sm:space-y-4">
            <div className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
              <h5 className="text-white font-medium text-sm sm:text-base mb-1 sm:mb-2">Initial Focus</h5>
              <div className="space-y-2">
                <div className="flex items-center text-gray-300">
                  <Users className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-blue-400" />
                  <span className="text-xs sm:text-sm">Security-Conscious Enterprises</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <Building className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-blue-400" />
                  <span className="text-xs sm:text-sm">Regulated Industries (Finance, Healthcare)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Next Milestones */}
      <div className="bg-blue-500/10 p-3 sm:p-6 rounded-lg border border-blue-400/30">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Next Milestones</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">3-5 Enterprise Clients</h4>
            <p className="text-xs sm:text-sm text-gray-300">Month 6 Target</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">2500-3000 Developers</h4>
            <p className="text-xs sm:text-sm text-gray-300">Initial Developer Community</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">$250K - $500K</h4>
            <p className="text-xs sm:text-sm text-gray-300">Month 6 Revenue Target</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractionSlide; 