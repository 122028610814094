import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { month: 'Jan', tokens: 1000 },
  { month: 'Feb', tokens: 2500 },
  { month: 'Mar', tokens: 4000 },
  { month: 'Apr', tokens: 6000 },
  { month: 'May', tokens: 8500 },
];

const TokenScalingChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="tokens" stroke="#60a5fa" fill="#60a5fa" fillOpacity={0.3} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TokenScalingChart; 