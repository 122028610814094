import React from 'react';
import { Users, LineChart, Target, Zap, Shield, Building, Bot } from 'lucide-react';

const partnershipReasons = [
  {
    title: "First-Mover Advantage",
    description: "Patent-protected position in rapidly growing AI-API market",
    icon: Target,
    color: "text-blue-400",
    bgColor: "bg-blue-400/10"
  },
  {
    title: "Network Effect Potential",
    description: "Natural platform expansion through API providers and developers",
    icon: Users,
    color: "text-purple-400",
    bgColor: "bg-purple-400/10"
  },
  {
    title: "85%+ Gross Margins",
    description: "Highly efficient, cloud-native architecture at scale",
    icon: LineChart,
    color: "text-green-400",
    bgColor: "bg-green-400/10"
  }
];

const timingReasons = [
  {
    title: "Market Evolution",
    description: "$41.5B market by 2030 with 89% AI-API growth",
    icon: Zap,
    color: "text-yellow-400",
    bgColor: "bg-yellow-400/10"
  },
  {
    title: "Technology Ready",
    description: "Patent-pending system with working POC",
    icon: Shield,
    color: "text-red-400",
    bgColor: "bg-red-400/10"
  },
  {
    title: "Validated Demand",
    description: "64% of businesses need AI-API integration",
    icon: Building,
    color: "text-blue-400",
    bgColor: "bg-blue-400/10"
  }
];

const opportunities = [
  {
    title: "Build a World-Class Team",
    icon: Users,
    color: "text-blue-400"
  },
  {
    title: "Accelerate Go-to-Market",
    icon: Zap,
    color: "text-purple-400"
  },
  {
    title: "Capture Market Share",
    icon: Target,
    color: "text-green-400"
  },
  {
    title: "Define API Future",
    icon: Bot,
    color: "text-yellow-400"
  }
];

const PartnershipAndTimingSlide = () => {
  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <p className="text-gray-300 text-sm sm:text-lg">
          Just as digital access systems became inevitable for physical security, unified API access management is becoming essential for digital security.
        </p>
      </div>

      {/* Partnership and Timing Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
        {/* Why Partner With Us */}
        <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Why Partner With Us</h4>
          <div className="space-y-3">
            {partnershipReasons.map((reason, index) => (
              <div key={index} className="flex items-start space-x-3 p-3 sm:p-4 bg-gray-700/50 rounded-lg">
                <div className={`p-2 rounded-lg ${reason.bgColor}`}>
                  <reason.icon className={reason.color} size={window.innerWidth < 640 ? 20 : 24} />
                </div>
                <div>
                  <h5 className="font-semibold text-white text-sm sm:text-base mb-1">{reason.title}</h5>
                  <p className="text-gray-300 text-xs sm:text-sm">{reason.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* The Time is Now */}
        <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
          <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">The Time is Now</h4>
          <div className="space-y-3">
            {timingReasons.map((reason, index) => (
              <div key={index} className="flex items-start space-x-3 p-3 sm:p-4 bg-gray-700/50 rounded-lg">
                <div className={`p-2 rounded-lg ${reason.bgColor}`}>
                  <reason.icon className={reason.color} size={window.innerWidth < 640 ? 20 : 24} />
                </div>
                <div>
                  <h5 className="font-semibold text-white text-sm sm:text-base mb-1">{reason.title}</h5>
                  <p className="text-gray-300 text-xs sm:text-sm">{reason.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Together We Can */}
      <div className="bg-blue-500/10 p-3 sm:p-6 rounded-lg border border-blue-400/30">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Together We Can</h4>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-4">
          {opportunities.map((opportunity, index) => (
            <div key={index} className="p-3 sm:p-4 bg-gray-800/50 rounded-lg text-center">
              <div className="flex justify-center mb-2 sm:mb-3">
                <opportunity.icon className={`${opportunity.color} w-5 h-5 sm:w-6 sm:h-6`} />
              </div>
              <h4 className="font-semibold text-white text-sm sm:text-base">{opportunity.title}</h4>
            </div>
          ))}
        </div>
      </div>

      {/* Bottom Section */}
      <div className="text-center space-y-2">
        <p className="text-lg sm:text-xl font-semibold text-blue-400">Join us in revolutionizing how the world manages APIs</p>
        <p className="text-base sm:text-lg text-gray-300">One Token. Many Doors.</p>
      </div>
    </div>
  );
};

export default PartnershipAndTimingSlide; 