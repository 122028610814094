import React, { useState } from 'react';
import { Shield, Zap, Bot, Key, DollarSign, Check, X } from 'lucide-react';

const competitors = [
  {
    name: "One Token API",
    features: {
      "Unified Token System": true,
      "Native AI Integration": true,
      "Usage-Based Pricing": true,
      "Enterprise Security": true,
      "Multi-API Support": true
    },
    highlight: true
  },
  {
    name: "Traditional Solutions",
    features: {
      "Unified Token System": false,
      "Native AI Integration": false,
      "Usage-Based Pricing": true,
      "Enterprise Security": true,
      "Multi-API Support": false
    }
  },
  {
    name: "API Gateways",
    features: {
      "Unified Token System": false,
      "Native AI Integration": false,
      "Usage-Based Pricing": false,
      "Enterprise Security": true,
      "Multi-API Support": true
    }
  }
];

const advantages = [
  {
    title: "Token Unification",
    description: "Single token access across multiple APIs",
    impact: "90% reduction in integration time",
    icon: Key,
    color: "text-blue-400"
  },
  {
    title: "AI-First Architecture",
    description: "Built for AI agent integration",
    impact: "First-to-market advantage in AI space",
    icon: Bot,
    color: "text-purple-400"
  },
  {
    title: "Cost Efficiency",
    description: "Innovative usage-based pricing model",
    impact: "75% cost savings vs traditional solutions",
    icon: DollarSign,
    color: "text-green-400"
  },
  {
    title: "Enterprise Security",
    description: "Advanced security and compliance features",
    impact: "Exceeds industry security standards",
    icon: Shield,
    color: "text-red-400"
  }
];

const CompetitiveSlide = () => {
  const [expandedAdvantage, setExpandedAdvantage] = useState(null);

  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <p className="text-gray-300 text-sm sm:text-lg">
          Our unified token system and AI-first approach create significant competitive advantages in the API management space.
        </p>
      </div>

      {/* Competitive Matrix */}
      <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Competitive Analysis</h4>
        <div className="relative overflow-x-auto">
          <table className="w-full text-left text-sm sm:text-base">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="p-2 sm:p-4">Features</th>
                {competitors.map((competitor, index) => (
                  <th key={index} className={`p-2 sm:p-4 text-center ${competitor.highlight ? 'text-blue-400' : 'text-gray-300'}`}>
                    {competitor.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(competitors[0].features).map((feature, index) => (
                <tr key={index} className="border-b border-gray-700/50">
                  <td className="p-2 sm:p-4 text-gray-300">{feature}</td>
                  {competitors.map((competitor, cIndex) => (
                    <td key={cIndex} className="p-2 sm:p-4 text-center">
                      {competitor.features[feature] ? (
                        <Check className="inline text-green-400 w-4 h-4 sm:w-5 sm:h-5" />
                      ) : (
                        <X className="inline text-red-400 w-4 h-4 sm:w-5 sm:h-5" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Key Advantages Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
        {advantages.map((advantage, index) => (
          <div
            key={index}
            className={`bg-gray-800/50 p-3 sm:p-6 rounded-lg cursor-pointer transition-all duration-300 hover:bg-gray-700/50 ${
              expandedAdvantage === index ? 'col-span-1 sm:col-span-2' : ''
            }`}
            onClick={() => setExpandedAdvantage(expandedAdvantage === index ? null : index)}
          >
            <div className="flex items-start space-x-3 sm:space-x-4">
              <div className={`p-2 sm:p-3 rounded-lg bg-gray-700 ${advantage.color}`}>
                <advantage.icon size={window.innerWidth < 640 ? 20 : 24} />
              </div>
              <div className="flex-1">
                <h4 className="text-base sm:text-lg font-semibold text-white mb-1 sm:mb-2">{advantage.title}</h4>
                <p className="text-gray-300 text-xs sm:text-sm">{advantage.description}</p>
                {expandedAdvantage === index && (
                  <div className="mt-2 sm:mt-4 p-2 sm:p-3 bg-gray-700/50 rounded-lg">
                    <p className="text-blue-400 font-semibold text-xs sm:text-sm">{advantage.impact}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Market Position */}
      <div className="bg-blue-500/10 p-3 sm:p-6 rounded-lg border border-blue-400/30">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Market Position</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4 text-center">
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-2 sm:mb-3">
              <Zap className="text-blue-400 w-5 h-5 sm:w-6 sm:h-6" />
            </div>
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">First Mover</h4>
            <p className="text-xs sm:text-sm text-gray-300">In AI-native API management</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-2 sm:mb-3">
              <Shield className="text-blue-400 w-5 h-5 sm:w-6 sm:h-6" />
            </div>
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">Patent Protected</h4>
            <p className="text-xs sm:text-sm text-gray-300">Unique token system</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-2 sm:mb-3">
              <Bot className="text-blue-400 w-5 h-5 sm:w-6 sm:h-6" />
            </div>
            <h4 className="font-semibold text-white text-sm sm:text-base mb-1 sm:mb-2">AI-First</h4>
            <p className="text-xs sm:text-sm text-gray-300">Built for next-gen needs</p>
          </div>
        </div>
      </div>

      {/* Solution Comparison */}
      <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-2 sm:mb-4">Traditional Solutions vs. OTA</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
          <div className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
            <h5 className="font-semibold text-white mb-2">Traditional</h5>
            <p className="text-gray-300 text-sm">Like managing separate keys for every building</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
            <h5 className="font-semibold text-white mb-2">API Gateways</h5>
            <p className="text-gray-300 text-sm">Like having a security desk at each building</p>
          </div>
          <div className="p-3 sm:p-4 bg-gray-700/50 rounded-lg">
            <h5 className="font-semibold text-blue-400 mb-2">OTA</h5>
            <p className="text-gray-300 text-sm">One intelligent keycard, unlimited secure access</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitiveSlide; 