export const slides = [
  {
    id: 'hero',
    title: "",
    subtitle: "",
    type: "hero"
  },
  {
    id: 'problem',
    title: "The Problem",
    content: "Market Challenge",
    type: "problem"
  },
  {
    id: 'solution',
    title: "Our Solution",
    content: "Unified Token Management",
    type: "solution"
  },
  {
    id: 'team',
    title: "Our Team",
    subtitle: "Leadership & Growth Strategy",
    type: "team"
  },
  {
    id: 'market',
    title: "Market Opportunity",
    type: "chart",
    chartComponent: "MarketGrowthChart"
  },
  {
    id: 'competitive',
    title: "Competitive Advantage",
    type: "comparison"
  },
  {
    id: 'strategy',
    title: "Go-to-Market Strategy",
    type: "strategy"
  },
  {
    id: 'business-model',
    title: "Business Model & Unit Economics",
    subtitle: "Revenue Streams & Cost Structure",
    type: "business-model"
  },
  {
    id: 'traction',
    title: "Traction & Milestones",
    type: "traction"
  },
  {
    id: 'revenue',
    title: "Revenue Projections",
    subtitle: "Growth Strategy & Financial Outlook",
    type: "revenue"
  },
  {
    type: 'funds',
    title: 'Use of Funds',
    subtitle: 'Strategic allocation for maximum impact'
  },
  {
    type: 'risks',
    title: 'Risks & Mitigations',
    subtitle: 'Identifying and addressing key challenges'
  },
  {
    type: 'partnership',
    title: 'Why Partner With Us Now?',
    subtitle: 'The right solution at the right time'
  }
]; 