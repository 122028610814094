import React from 'react';
import { Key, DollarSign, Shield, Bot } from 'lucide-react';

const painPoints = [
  {
    title: "System Integration Complexity",
    description: "63% of enterprises struggle with using APIs to bridge and manage disparate systems effectively",
    impact: "By 2024, 75% of mobile apps will require hybrid architectures with API integration",
    icon: Key,
    color: "text-yellow-400"
  },
  {
    title: "Security & Authentication",
    description: "70% of public APIs use RESTful architectures requiring complex key authentication management",
    impact: "56% of leaders see APIs as crucial for digital products, making security critical",
    icon: Shield,
    color: "text-red-400"
  },
  {
    title: "Web Application Dependencies",
    description: "57% of web applications are now powered by APIs, creating significant management overhead",
    impact: "Growing complexity in managing multiple API integrations and dependencies",
    icon: DollarSign,
    color: "text-green-400"
  },
  {
    title: "AI Integration Challenges",
    description: "64% of businesses expect AI to increase productivity, but face API integration hurdles",
    impact: "25% of companies adopting AI for automation need simplified API access",
    icon: Bot,
    color: "text-purple-400"
  }
];

const marketPains = [
  {
    percentage: "-0.175",
    description: "Negative sentiment on billing complexity and lack of transparent pricing"
  },
  {
    percentage: "56%",
    description: "of leaders see APIs as crucial yet face integration challenges"
  },
  {
    percentage: "64%",
    description: "of businesses need better API solutions for AI productivity"
  }
];

const ProblemSlide = () => {
  return (
    <div className="space-y-4 sm:space-y-8">
      {/* Problem Statement */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h3 className="text-xl sm:text-2xl font-semibold text-blue-400 mb-2">The Problem</h3>
        <p className="text-gray-300 text-sm sm:text-base mb-6">
          Imagine managing an office building where every tenant has a different master key that, if lost, requires changing all the locks. That's today's API economy: exponentially growing, yet burdened with fragmented, risky access management.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {painPoints.map((pain, index) => (
            <div key={index} className="p-4 sm:p-6 bg-gray-700/50 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className={`p-2 sm:p-3 rounded-lg bg-gray-800 ${pain.color} flex-shrink-0`}>
                  <pain.icon size={window.innerWidth < 640 ? 20 : 24} />
                </div>
                <div>
                  <h4 className="font-semibold text-white text-base sm:text-lg mb-2">{pain.title}</h4>
                  <p className="text-gray-300 text-sm sm:text-base">{pain.description}</p>
                  <p className="text-blue-400 text-sm sm:text-base mt-2">{pain.impact}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Market Size */}
      <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
        <h4 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Market Challenges</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {marketPains.map((stat, index) => (
            <div key={index} className="text-center p-4 sm:p-6 bg-gray-700/50 rounded-lg">
              <div className="text-2xl sm:text-3xl font-bold text-white mb-2">
                {stat.percentage}
              </div>
              <div className="text-blue-300 font-medium text-sm sm:text-base">{stat.description}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Pain Points */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        {/* Developer Pain Points */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">Developer Pain Points</h4>
          <div className="space-y-3">
            {painPoints.map((point, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 p-3 sm:p-4 bg-gray-700/50 rounded-lg"
              >
                <point.icon className="text-red-400 w-5 h-5 sm:w-6 sm:h-6 flex-shrink-0 mt-0.5" />
                <div>
                  <h5 className="font-semibold text-white text-sm sm:text-base mb-1">{point.title}</h5>
                  <p className="text-gray-300 text-xs sm:text-sm">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* API Provider Pain Points */}
        <div className="bg-gray-800/50 p-4 sm:p-6 rounded-lg">
          <h4 className="text-lg sm:text-xl font-semibold text-blue-400 mb-4">API Provider Pain Points</h4>
          <div className="space-y-3">
            {painPoints.map((point, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 p-3 sm:p-4 bg-gray-700/50 rounded-lg"
              >
                <point.icon className="text-yellow-400 w-5 h-5 sm:w-6 sm:h-6 flex-shrink-0 mt-0.5" />
                <div>
                  <h5 className="font-semibold text-white text-sm sm:text-base mb-1">{point.title}</h5>
                  <p className="text-gray-300 text-xs sm:text-sm">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemSlide; 