import React, { useState } from 'react';
import { Building, Shield, GitBranch, MessageCircle, BookOpen, Code } from 'lucide-react';

const salesStages = [
  {
    stage: "Awareness",
    duration: "1-2 months",
    activities: [
      "Technical content marketing",
      "Industry event presence",
      "Developer community engagement"
    ]
  },
  {
    stage: "Evaluation",
    duration: "2-3 months",
    activities: [
      "Security assessment",
      "Technical documentation",
      "Compliance review"
    ]
  },
  {
    stage: "Pilot",
    duration: "2-3 months",
    activities: [
      "Limited deployment",
      "Integration testing",
      "Success metrics tracking"
    ]
  },
  {
    stage: "Enterprise Rollout",
    duration: "3-4 months",
    activities: [
      "Full deployment",
      "Team training",
      "Support establishment"
    ]
  }
];

const customerSegments = [
  {
    name: "Primary Focus",
    icon: Shield,
    color: "text-blue-400",
    segments: [
      "Financial Services",
      "Healthcare Providers",
      "Insurance Companies"
    ],
    characteristics: [
      "High security requirements",
      "Complex compliance needs",
      "Multiple API integrations"
    ]
  },
  {
    name: "Secondary Target",
    icon: Building,
    color: "text-green-400",
    segments: [
      "Enterprise Software",
      "Cloud Service Providers",
      "Large Tech Companies"
    ],
    characteristics: [
      "Significant API usage",
      "Security awareness",
      "Integration complexity"
    ]
  }
];

const communityEngagement = [
  {
    title: "Developer Relations",
    icon: GitBranch,
    color: "text-purple-400",
    activities: [
      "Open source contributions",
      "Developer advocacy program",
      "Technical workshops"
    ]
  },
  {
    title: "Content Strategy",
    icon: BookOpen,
    color: "text-yellow-400",
    activities: [
      "Technical blog posts",
      "API best practices",
      "Security guidelines"
    ]
  },
  {
    title: "Community Building",
    icon: MessageCircle,
    color: "text-red-400",
    activities: [
      "Developer forums",
      "Discord community",
      "Regular webinars"
    ]
  }
];

const growthTrajectory = {
  conservative: {
    name: "Conservative Plan ($2.5M)",
    year1: {
      total: "$1M Total",
      runRate: "$1.9M Q4 Run Rate",
      quarters: [
        { q: "Q1", value: "$75K" },
        { q: "Q2", value: "$150K" },
        { q: "Q3", value: "$300K" },
        { q: "Q4", value: "$475K" }
      ]
    },
    year2: "$5M Total ($6.8M Q4 Run Rate)",
    year3: "$15M Total ($20M Q4 Run Rate)"
  },
  aggressive: {
    name: "Aggressive Plan ($5M)",
    year1: {
      total: "$1.6M Total",
      runRate: "$3M Q4 Run Rate",
      quarters: [
        { q: "Q1", value: "$100K" },
        { q: "Q2", value: "$250K" },
        { q: "Q3", value: "$500K" },
        { q: "Q4", value: "$750K" }
      ]
    },
    year2: "$8M Total ($11.6M Q4 Run Rate)",
    year3: "$23.7M Total ($32M Q4 Run Rate)"
  }
};

const adoptionPaths = {
  enterprise: {
    title: "Enterprise Track",
    icon: Building,
    color: "text-blue-400",
    details: [
      "Target: Security-conscious organizations",
      "Focus: Financial Services, Healthcare, Insurance",
      "Key Value: Compliance, Security, Cost Reduction",
      "Sales Cycle: 6-9 months",
      "Initial ACV: $50K+"
    ]
  },
  developer: {
    title: "Developer Track",
    icon: Code,
    color: "text-green-400",
    details: [
      "Target: Development teams managing 6-20 APIs",
      "Focus: API-first companies, Tech teams in regulated industries",
      "Key Value: Simplification, Security, Time Savings",
      "Sales Cycle: 30-90 days",
      "Initial ACV: $1K-10K"
    ]
  }
};

const marketPhases = [
  {
    title: "Foundation Phase (Q1-Q2)",
    items: [
      "Security-first features",
      "Developer portal launch",
      "Initial enterprise pilots",
      "Key integrations",
      "Target: 200-500 developers, 2-3 enterprises"
    ]
  },
  {
    title: "Market Expansion (Q3-Q4)",
    items: [
      "Enterprise security features",
      "Compliance frameworks",
      "Regional partnerships",
      "Reference customers",
      "Target: 1,000-2,000 developers, 5-8 enterprises"
    ]
  },
  {
    title: "Scale Phase (Y2)",
    items: [
      "Global infrastructure",
      "Advanced features",
      "Channel partnerships",
      "Industry verticals",
      "Target: 3,000-5,000 developers, 15-20 enterprises"
    ]
  }
];

const successMetrics = {
  developer: {
    title: "Developer Platform",
    sections: [
      {
        title: "Core Metrics",
        metrics: [
          { label: "Trial-to-Paid Conversion", value: "20-25%" },
          { label: "Monthly Churn", value: "<3%" }
        ]
      },
      {
        title: "Platform Usage",
        metrics: [
          { label: "Multi-API Management", value: "80%" },
          { label: "Service Uptime", value: "95%" },
          { label: "Token Validation Time", value: "<5ms" }
        ]
      }
    ]
  },
  enterprise: {
    title: "Enterprise Success",
    sections: [
      {
        title: "Implementation Success",
        metrics: [
          { label: "Contract-to-Live", value: "<45 days" },
          { label: "Security Compliance", value: "<30 days" },
          { label: "First API Integration", value: "<7 days" }
        ]
      },
      {
        title: "Platform Adoption",
        metrics: [
          { label: "API Migration Rate", value: "80%" },
          { label: "Management Time Reduction", value: "50%" },
          { label: "Security Incidents", value: "Zero" }
        ]
      },
      {
        title: "Cost Efficiency",
        metrics: [
          { label: "Management Cost Reduction", value: "30-40%" },
          { label: "Onboarding Time Reduction", value: "25%" },
          { label: "API Transaction Overhead", value: "<1%" }
        ]
      }
    ]
  }
};

const GoToMarketSlide = () => {
  const [selectedPlan, setSelectedPlan] = useState('conservative');
  
  return (
    <div className="space-y-4 sm:space-y-8 p-2 sm:p-6">
      {/* Header Section */}
      <div className="space-y-2 sm:space-y-4">
        <h3 className="text-xl sm:text-2xl font-semibold text-blue-400">Go-to-Market Strategy</h3>
        <p className="text-gray-300 text-sm sm:text-base">
          Our dual-track approach combines enterprise security needs with developer-friendly adoption paths
        </p>
      </div>

      {/* Growth Trajectory */}
      <div className="bg-gray-800/50 p-3 sm:p-6 rounded-lg">
        <h4 className="text-base sm:text-lg font-semibold text-blue-400 mb-4">Growth Trajectory</h4>
        
        {/* Plan Selection */}
        <div className="flex space-x-4 mb-6">
          {Object.entries(growthTrajectory).map(([key, plan]) => (
            <button
              key={key}
              onClick={() => setSelectedPlan(key)}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedPlan === key 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-gray-300'
              }`}
            >
              {plan.name}
            </button>
          ))}
        </div>

        {/* Selected Plan Details */}
        <div className="space-y-6">
          {/* Year 1 Details */}
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <div className="text-center mb-4">
              <h5 className="text-lg font-semibold text-white">
                {growthTrajectory[selectedPlan].year1.total}
              </h5>
              <p className="text-sm text-blue-400">
                {growthTrajectory[selectedPlan].year1.runRate}
              </p>
            </div>
            
            <div className="grid grid-cols-4 gap-4">
              {growthTrajectory[selectedPlan].year1.quarters.map((quarter, idx) => (
                <div key={idx} className="text-center p-3 bg-gray-800/50 rounded-lg">
                  <div className="text-sm font-medium text-gray-300">{quarter.q}</div>
                  <div className="text-lg font-semibold text-white">{quarter.value}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Future Years */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="p-4 bg-gray-700/50 rounded-lg text-center">
              <p className="text-sm text-gray-300 mb-2">Year 2</p>
              <p className="text-lg font-semibold text-white">
                {growthTrajectory[selectedPlan].year2}
              </p>
            </div>
            <div className="p-4 bg-gray-700/50 rounded-lg text-center">
              <p className="text-sm text-gray-300 mb-2">Year 3</p>
              <p className="text-lg font-semibold text-white">
                {growthTrajectory[selectedPlan].year3}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Dual Adoption Paths */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {Object.values(adoptionPaths).map((path, index) => (
          <div key={index} className="bg-gray-800/50 p-4 rounded-lg">
            <div className="flex items-center space-x-3 mb-4">
              <div className={`p-2 rounded-lg bg-gray-700 ${path.color}`}>
                <path.icon size={24} />
              </div>
              <h4 className="text-lg font-semibold text-white">{path.title}</h4>
            </div>
            <div className="space-y-2">
              {path.details.map((detail, idx) => (
                <p key={idx} className="text-sm text-gray-300">• {detail}</p>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Market Entry Strategy */}
      <div className="bg-gray-800/50 p-4 rounded-lg">
        <h4 className="text-lg font-semibold text-blue-400 mb-4">Market Entry Strategy</h4>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {marketPhases.map((phase, index) => (
            <div key={index} className="bg-gray-700/50 p-4 rounded-lg">
              <h5 className="font-semibold text-white mb-3">{phase.title}</h5>
              <div className="space-y-2">
                {phase.items.map((item, idx) => (
                  <p key={idx} className="text-sm text-gray-300">• {item}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Success Metrics */}
      <div className="space-y-4">
        {Object.values(successMetrics).map((platform, index) => (
          <div key={index} className="bg-gray-800/50 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-blue-400 mb-6">{platform.title}</h4>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              {platform.sections.map((section, sectionIdx) => (
                <div key={sectionIdx} className="bg-gray-700/50 p-4 rounded-lg">
                  <h5 className="text-white font-medium text-sm mb-4">{section.title}</h5>
                  <div className="space-y-3">
                    {section.metrics.map((metric, metricIdx) => (
                      <div key={metricIdx} className="flex flex-col">
                        <div className="text-2xl font-bold text-white">{metric.value}</div>
                        <div className="text-sm text-gray-300">{metric.label}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoToMarketSlide; 