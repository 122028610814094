import React from 'react';

const MarketFunnel = () => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="w-full p-4 bg-blue-500/20 rounded-lg text-center">
        <h4 className="text-white font-semibold">Total API Management Market</h4>
        <p className="text-2xl text-blue-400 font-bold">$41.5B</p>
      </div>
      <div className="w-3/4 p-4 bg-blue-500/30 rounded-lg text-center">
        <h4 className="text-white font-semibold">Serviceable Addressable Market</h4>
        <p className="text-2xl text-blue-400 font-bold">$15.2B</p>
        <p className="text-sm text-gray-300">Enterprise & Mid-Market Focus</p>
      </div>
      <div className="w-1/2 p-4 bg-blue-500/40 rounded-lg text-center">
        <h4 className="text-white font-semibold">Initial Target Market</h4>
        <p className="text-2xl text-blue-400 font-bold">$5.1B</p>
        <p className="text-sm text-gray-300">Security-Critical Industries</p>
      </div>
    </div>
  );
};

export default MarketFunnel; 